import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const works = data.work.nodes
  const timeline = data.timeline.nodes
  const writings = data.writings.nodes

  // if (posts.length === 0) {
  //   return (
  //     <Layout location={location} title={siteTitle}>
  //       <Seo title="All posts" />
  //       <Bio />
  //       <p>
  //         No blog posts found. Add markdown posts to "content/blog" (or the
  //         directory you specified for the "gatsby-source-filesystem" plugin in
  //         gatsby-config.js).
  //       </p>
  //     </Layout>
  //   )
  // }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Kjartan Kennedy" />

      <section className="spacing-double-extra-large spacing-top-double-extra-large">
        <div className="container">
          <div className="spacing-extra-large">
            <h1 className="intro spacing-large">I'm Kjartan —</h1>
            <span className="titles">
              Designer
              <br />
              Builder
              <br />
              Strategist
              <br />
            </span>
          </div>
          <div className="spacing-large">
            <p className="text-large spacing-medium">
              My personal mission is to be a force-multiplier and enabler for
              creative people.
            </p>
            <p className="spacing-medium">
              These days, I’m serving that mission by running Causeway Studios,
              serving on the board of the Game Academy of the Ozarks, and
              designing and building tools for Unity users.
            </p>
            <p>
              I ask difficult questions to facilitate the crafting of great
              experiences.
            </p>
          </div>
          <div>
            <h2 className="spacing-medium">Availability</h2>
            <p className="spacing-medium">
              I’m available to consult on design for startups, helping you
              define your problems and opportunities to craft the right
              solutions.
            </p>
            <Link to="/contact">
              <button>Get in touch</button>
            </Link>
          </div>
        </div>
      </section>

      <section className="spacing-double-extra-large">
        <div className="container">
          <h2 className="spacing-medium-large">Featured Work</h2>
          <div className="featured-work-grid">
            {works.map((work, index) => {
              const title = work.frontmatter.title || work.fields.slug
              const place = work.frontmatter.place
              let image = getImage(work.frontmatter.thumb)

              return (
                // <li key={work.fields.slug}>
                <article
                  className={
                    "featured-work-item " + (index > 2 && "hide-mobile")
                  }
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <GatsbyImage
                    image={image}
                    alt={title}
                    objectPosition="50% 50%"
                    className="featured-work-thumbnail"
                  />
                  <div className="featured-work-overlay">
                    <h3 className="spacing-tiny">{title}</h3>
                    <p className="text-small">{place}</p>
                  </div>
                </article>
                // </li>
              )
            })}
            <article
              className="featured-work-add"
              itemScope
              itemType="http://schema.org/Article"
            >
              <h3 className="spacing-small">Want to add to this list?</h3>
              <Link to="/contact">
                <button>Get in touch</button>
              </Link>
            </article>
          </div>
        </div>
      </section>

      <section className="spacing-double-extra-large">
        <div className="container">
          <h2 className="spacing-medium-large">Timeline</h2>
          <ul>
            {timeline.map(event => {
              const location = event.frontmatter.location
              const description = event.frontmatter.description
              const date = event.frontmatter.date
              const icon = event.frontmatter.icon
              const details = event.frontmatter.details

              let image = getImage(event.frontmatter.icon)

              return (
                <li className="timeline-item" key="1">
                  <StaticImage
                    className="timeline-node"
                    layout="fixed"
                    src="../images/timeline-node.png"
                    width={24}
                    height={24}
                    quality={100}
                    alt=""
                  />
                  <div className="timeline-month">{date}</div>
                  <div className="timeline-event">
                    <div className="event-icon-container">
                      <GatsbyImage
                        image={image}
                        alt={location}
                        className="event-icon"
                        width={32}
                        height={32}
                      />
                    </div>
                    <div>
                      <div className="event-info">
                        <span className="event-location">{location}</span>
                        <span className="event-spacer">•</span>
                        <span
                          className="event-description"
                          dangerouslySetInnerHTML={{
                            __html: description,
                          }}
                        ></span>
                      </div>
                      {details && (
                        <p className="text-small event-details">{details}</p>
                      )}
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </section>

      {/* <section className="spacing-double-extra-large">
        <div className="container">
          <h2 className="spacing-medium-large">Recent Writing</h2>
          <ul>
            {writings.map(post => {
              const title = post.frontmatter.title || post.fields.slug

              return (
                <li key={post.fields.slug}>
                  <article
                    className="post-list-item"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    
                  </article>
                </li>
              )
            })}
          </ul>
        </div>
      </section> */}

      {/* <Bio /> */}
      {/* <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol> */}
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    work: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/work/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          place
          description
          thumb {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.5)
            }
          }
        }
      }
    }
    writings: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/writings/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
    timeline: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/timeline/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        frontmatter {
          date(formatString: "MMM. 'YY")
          location
          description
          details
          icon {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
